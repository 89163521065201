<template>
  <div v-if="modules">
    <router-link
      v-for="(module, index) in modules"
      :key="index"
      :id="`card-module-${index}`"
      :to="`/curso/${module.course.id}/modulo/${module.id}/`"
    >
      <div
        class="module-card"
        :class="{ 'completed-border': moduleCompleted(module) }"
      >
        <span
          class="module-title"
          :class="{ 'module-title-completed': moduleCompleted(module) }"
          >{{ module.category }} - {{ module.course.title }}</span
        >
        <p class="module-description">{{ module.title }}</p>

        <div v-if="!moduleCompleted(module)" class="module-footer">
          <div class="icon-button" v-if="module.total_lessons > 0">
            <IconSax type="bold" name="video-circle" />
            <span>
              {{ module.watched_lessons }}/{{ module.total_lessons }}
            </span>
          </div>
          <div class="icon-button" v-if="module.total_exercises > 0">
            <IconSax type="outline" name="clipboard-tick" />
            <span>
              {{ module.completed_exercises }}/{{ module.exercises.total }}
            </span>
          </div>
        </div>

        <div class="module-footer-finished" v-else>
          <div class="icon-button-success">
            <IconSax type="bold" name="tick-circle" />
            <span>Concluído</span>
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "ColumnCard",
  props: {
    modules: {
      type: Array,
      required: true,
    },
  },
  methods: {
    moduleCompleted(module) {
      return (
        module.watched_lessons === module.total_lessons &&
        module.completed_exercises === module.total_exercises
      );
    },
  },
};
</script>

<style style lang="scss" scoped>
.module-card {
  background-color: var(--neutral-white);
  border-radius: 4px;
  padding: 1rem;
  margin: 0.5rem 0;
  box-sizing: border-box;
  border: 1px solid var(--bordercolor2);

  &:hover {
    border: 1px solid var(--maincolor);
  }

  &.completed-border {
    border: 1px solid var(--success);
  }
}

.module-title {
  font-family: Inter;
  font-weight: 600;
  font-size: 16px;
  color: var(--maincolor);

  &-completed {
    color: var(--fontcolor);
  }
}

.module-description {
  font-family: Inter;
  font-weight: 400;
  font-size: 16px;
  color: var(--fontcolor);
  margin-top: 0.5rem;
}

.module-footer,
.module-footer-finished {
  display: flex;
  gap: 1.5rem;
  margin-top: 1rem;
  font-family: Inter;
  font-weight: 600;
  font-size: 14px;
  color: var(--maincolor);

  &-finished {
    color: var(--success);
  }
}

.icon-button,
.icon-button-success {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: bold;

  ::v-deep .svgCss {
    svg {
      width: 1rem;
      height: 1rem;
    }

    path {
      fill: var(--maincolor) !important;
    }
  }
}

.week-arrows {
  display: flex;
  align-items: center;
  font-weight: bold;
  gap: 1rem;

  ::v-deep .svgCss {
    svg {
      width: 2rem;
      height: 2rem;
    }

    path {
      fill: var(--maincolor) !important;
      cursor: pointer;
    }
  }
}

.icon-button-success ::v-deep .svgCss svg path {
  fill: var(--success) !important;
}
</style>
